<template>
    <CompContent title="数据赋能">
        <template #operate>
            <Button type="primary" @click="route.jump(`/residentdatabasemybusiness?orgCode=${orgCode}&type=street`)">报表管理</Button>
            <Button type="info" @click="route.jump('/residentdatabasestreetexport')">导出管理</Button>
        </template>
        <div class="statistics-box">
            <div class="item-box" v-for="item in list" :key="item.appId" @click="route.jump('/residentdatabasestreetstatistics', { appId: item.appId })">
                <p class="title">{{ item.appName }}</p>
                <p class="value">{{ item.count }}</p>
                <div class="proportion">
                    <p>占街镇</p>
                    <p>{{ item.percent || "0.00%" }}</p>
                </div>
            </div>

            <div class="empty-box" v-if="list && list.length === 0">暂无数据，点击右上角报表管理开始添加吧！</div>
        </div>
    </CompContent>
</template>

<script>
import Route from "../../communitymobilization/entity/Route"
import Street from "../../managsystem/utils/street"

import LiefengContent from "@/components/LiefengContent3.vue"
import Request from "../../jointly/utils/request"
import CompContent from "../../customform/components/CompContent.vue"

export default {
    components: { LiefengContent, CompContent },

    data() {
        return {
            route: Route,
            list: null,
            orgCode: null,
        }
    },

    async created() {
        const info = await Street.getInfo()
        // 街道编码
        this.orgCode = info.orgCode
        // 获取数据
        this.getData()
    },

    methods: {
        getData() {
            Request.get("/syaa/api/syuser/pc/streetUser/countByAllApp?streetCode=" + this.orgCode).then(res => {
                this.list = res
            })
        },
    },
}
</script>

<style lang="less" scoped>
.statistics-box {
    display: flex;
    margin: 20px 0 10px 0;
    flex-wrap: wrap;

    > .item-box {
        cursor: pointer;
        position: relative;
        width: calc(100% / 6 - 10px);
        max-width: 160px;
        min-width: 120px;
        padding: 10px;
        margin: 5px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: #f4fafd;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        box-sizing: border-box;

        &:last-child::after {
            background: initial;
        }

        > .title {
            font-size: 13px;
            color: #666;
            line-height: 20px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-width: 100%;
            text-align: center;
        }

        .value {
            font-size: 28px;
            font-weight: bold;
            color: #0091ff;
        }

        .proportion {
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: #999;
            font-size: 12px;
        }
    }

    .empty-box {
        position: absolute;
        right: 0;
        left: 0;
        text-align: center;
        margin: 50px 20px;
        font-size: 14px;
        color: #666;
    }
}
</style>
